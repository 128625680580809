@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.faq {
    padding: 80px 0;

    @include lg {
        padding: 150px 0;
    }

    @include xl {
        padding: 180px 0;
    }

    &__list {
        margin-top: 20px;

        @include lg {
            margin-top: 50px;
        }
    }

    &__item {
        display: flex;
        padding: 15px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
        cursor: pointer;

        &:last-child {
            border-bottom: 1px solid rgba(255, 255, 255, 0.3);
        }

        @include lg {
            padding: 20px 0;
        }

        & > span {
            padding-right: 5px;
            font-size: 12px;
            line-height: 120%;
            opacity: 0.5;

            @include lg {
                padding-right: 10px;
                font-size: 20px;
            }

            @include xl {
                padding-right: 90px;
            }
        }

        & .content {
            flex: 1 1 auto;
            width: 250px;
            overflow-y: hidden;

            @include sm {
                width: 540px;
            }

            @include xl {
                padding-right: 90px;
            }

            h5 {
                font-weight: 400;
                font-size: 18px;
                line-height: 120%;
                transition: margin 0.5s;

                @include lg {
                    font-size: 30px;
                }
            }

            p {
                max-height: 0;
                max-width: 730px;
                font-size: 14px;
                line-height: 130%;
                transition-duration: 0.5s;

                @include lg {
                    font-size: 16px;
                }

                & .green-link {
                    color: $green;
                    text-decoration: underline;
                    transition: opacity 0.3s;

                    @include only-hover {
                        &:hover {
                            opacity: 0.8;
                        }
                    }
                }
            }
        }

        &.opened .content p {
            height: auto;
            max-height: 300px;
            margin-top: 10px;

            @include lg {
                margin-top: 15px;
            }
        }

        & .cross {
            position: relative;
            width: 24px;
            height: 24px;
            transition-duration: 500ms;
            cursor: pointer;

            &-vertical,
            &-horizontal {
                background-color: $white;
                position: absolute;
                transition-duration: 300ms;
            }

            &-vertical {
                left: 11px;
                width: 2px;
                height: 24px;

                @include lg {
                    left: 15px;
                    height: 32px;
                }
            }

            &-horizontal {
                width: 24px;
                height: 2px;
                top: 11px;

                @include lg {
                    top: 15px;
                    width: 32px;
                }
            }

            @include lg {
                width: 32px;
                height: 32px;
            }
        }

        @include lg {
            @include only-hover {
                &:hover {
                    & .content h5,
                    & .content p {
                        margin-left: 20px;
                    }

                    & .cross {
                        margin-right: 20px;
                    }
                }
            }
        }

        &.opened {
            & .cross-vertical {
                transform: rotate(90deg);
            }
        }
    }
}
