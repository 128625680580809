@import "../../../../styles/vars";
@import "../../../../styles/mixins";
@import "../../../../styles/common";

.hero {
    padding: 40px 0 26px;

    @include sm {
        padding: 70px 0 94px;
    }

    @include lg {
        padding: 60px 0 116px;
    }

    @include xl {
        padding: 40px 0 222px;
    }

    &__header {
        &-img {
            grid-area: img;
            margin: -20px auto 0;
            width: 265px;
            height: 159px;

            img {
                width: 100%;
                height: 100%;
            }
        }

        @include sm {
            display: grid;
            column-gap: 35px;
            grid-template:
                "title img"
                "subtitle img";

            &-img {
                margin-top: -54px;
            }
        }

        @include lg {
            display: block;

            &-img.tablet {
                display: none;
            }
        }

        & .first {
            opacity: 0;
            animation: appearance 1.7s 0.1s forwards;
        }

        & .second {
            opacity: 0;
            animation: appearance 1.5s 0.3s forwards;
        }

        & .third {
            opacity: 0;
            animation: appearance 1.5s 0.5s forwards;
        }

        & .fourth {
            opacity: 0;
            animation: appearance 1.5s 0.7s forwards;
        }

        & .fifth {
            opacity: 0;
            animation: appearance 1.5s 0.9s forwards;
        }
    }

    & .six {
        opacity: 0;
        animation: appearance 1.5s 1.1s forwards;
    }

    &__title {
        grid-area: title;
        font: inherit;
        font-size: 25px;
        line-height: 110%;
        letter-spacing: -0.02em;
        max-width: 300px;

        & .desktop {
            @media (max-width: 991px) {
                display: none;
            }
        }

        @include lg {
            display: flex;
            flex-wrap: wrap;
            font-size: 60px;
            letter-spacing: -0.01em;
            max-width: 900px;

            picture.desktop {
                img {
                    margin: -45px -12px -25px -7px;
                    width: 190px;
                    height: 115px;
                }
            }
        }

        @include xl {
            font-size: 80px;
            max-width: 1200px;

            picture.desktop {
                img {
                    margin: -55px -15px -25px -10px;
                    width: 264px;
                    height: 159px;
                }
            }
        }
    }

    &__subtitle {
        grid-area: subtitle;
        margin-top: 40px;
        font-size: 14px;
        line-height: 130%;
        letter-spacing: -0.02em;
        max-width: 240px;

        @include lg {
            letter-spacing: -0.01em;
        }

        @include sm {
            margin-top: 15px;
        }

        @include lg {
            margin-top: 40px;
            max-width: 405px;
            font-size: 20px;
        }

        @include xl {
            margin-top: 50px;
        }
    }

    &__form {
        margin-top: 15px;

        @include sm {
            margin-top: 20px;
            display: grid;
            grid-template:
                "name phone"
                "button button" / 1fr 1fr;
        }

        @include lg {
            grid-template: "name phone button" / 1fr 1fr 1fr;
        }

        @include xl {
            margin-top: 30px;
        }

        &-field {
            width: 100%;
        }

        &-name {
            grid-area: name;
        }

        &-phone {
            grid-area: phone;
        }

        &-button {
            grid-area: button;
        }

        button,
        button span {
            width: 100%;
        }
    }
}

@keyframes appearance {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
