@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.contacts {
    &__wrapper {
        position: relative;
        z-index: 5;
    }

    &__inner {
        padding: 80px 0;

        @include lg {
            padding: 100px 0;
        }
    }

    &__header {
        position: relative;

        img {
            position: absolute;
            top: 0;
            right: 0;
            width: 24px;
            height: 24px;

            @include lg {
                width: 60px;
                height: 60px;
            }
        }
    }

    &__title {
        font-weight: 400;
        font-size: 25px;
        line-height: 110%;

        @include sm {
            max-width: 420px;
        }

        @include lg {
            font-size: 60px;
            max-width: 900px;
        }

        @include xl {
            padding-right: 100px;
        }
    }

    &__content {
        margin-top: 15px;
        display: flex;
        flex-direction: column;

        @include lg {
            margin-top: 30px;
            flex-direction: row;
        }
    }

    &__subtitle {
        max-width: 367px;
        font-size: 14px;
        line-height: 130%;

        @include lg {
            font-size: 20px;
        }
    }

    &__form {
        width: 300px;

        @include sm {
            width: 600px;
        }

        @include lg {
            width: 450px;
        }

        @include xl {
            width: 600px;
        }

        &-fields {
            margin-top: 15px;
            flex-direction: column;
            row-gap: 15px;

            @include lg {
                row-gap: 30px;
                margin-top: 30px;
            }

            button {
                display: block;
                margin-top: 0;
                align-self: flex-start;
                width: 100%;

                span {
                    display: block;
                    padding: 20px 40px;
                }
            }
        }

        &-desc {
            margin-top: 15px;
            color: rgba(255, 255, 255, 0.5);
        }
    }

    &__communications {
        display: flex;
        flex-direction: column;
        row-gap: 20px;
        padding-top: 40px;

        @include sm {
            flex-direction: row;
            column-gap: 30px;
        }

        @include lg {
            flex-direction: column;
            padding: 0 0 0 50px;
            row-gap: 57px;
        }

        @include xl {
            padding-left: 90px;
        }

        &-phone {
            font-size: 14px;
            line-height: 120%;

            @include lg {
                font-size: 30px;
            }
        }

        &-socials {
            display: flex;
            align-items: center;
            column-gap: 30px;

            a {
                font-size: 14px;
                line-height: 100%;
            }

            @include lg {
                a {
                    font-size: 18px;
                }
            }
        }
    }
}
