.letsWorkTogether{
    padding-top: 90px;
    padding-bottom: 100px;
    background-color: #191919;
    position: relative;
    z-index: 5;
}
.letsWorkTogether .firstLine{
    justify-content: space-between;
}
.letsWorkTogether .firstLine .title h2{
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.letsWorkTogether .firstLine .fireCircle{
    background: rgba(255, 255, 255, 0.15);
    width: 80px;
    height: 80px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 8px;
    margin-right: 40px;
}
.letsWorkTogether .title{
    margin-top: 2px;
}
.letsWorkTogether .firstLine > img{
    height: min-content;
    margin-right: -13px;
}
.letsWorkTogether .secondLine{
    height: 1px;
    width: 100%;
    background-color: rgba(255, 255, 255, 0.2);
    margin-top: 60px;
    position: relative;
}
.letsWorkTogether .absHolder{
    top: -109px;
    position: absolute;
    right: 0px;

}
.letsWorkTogether .secondLine button{
    width: 220px;
    height: 220px;
    background-color: #388E3C;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    transition-duration: 300ms;
}
.letsWorkTogether .secondLine .animatedCircleButton .buttonBack{
    background-color: #569F59;
}
.letsWorkTogether .thirdLine{
    margin-top: 60px;
}
.letsWorkTogether .thirdLine a{
    width: 248px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: left;
    border: 1px solid rgba(255, 255, 255, 0.2);
    border-radius: 30px;
    transition-duration: 300ms;
}
.letsWorkTogether .thirdLine a:first-child{
    margin-right: 20px;
}
.letsWorkTogether .thirdLine a:hover{
    border-color: #388E3C;
}
.footerLinksMob{
    display: none;
}
@media (max-width: 1199px) {
    .letsWorkTogether .thirdLine{
        margin-top: 50px;
    }
    .letsWorkTogether .absHolder{
        right: 20px!important;
        transform: none!important;
    }
}
@media (max-width: 899px) {
    .letsWorkTogether{
        padding-top: 50px;
        padding-bottom: 60px;
    }
    .letsWorkTogether .firstLine .title h2{
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;

    }
    .letsWorkTogether .firstLine .fireCircle{
        width: 40px;
        height: 40px;
        margin-right: 10px;
        margin-top: 0;
    }
    .letsWorkTogether .title{
        margin-top: 10px;
    }
    .letsWorkTogether .firstLine .fireCircle img{
        width: 20px;
        height: 20px;
    }
    .letsWorkTogether .title > .row{
        display: flex;
        align-items: center;
    }
    .letsWorkTogether .secondLine{
        margin-top: 123px;

    }
    .letsWorkTogether .absHolder{
        left: calc(50% - 100px);
    }
    .letsWorkTogether .thirdLine{
        margin-top: 130px;
    }
    .letsWorkTogether .thirdLine a{
        width: 295px;
        height: 50px;
    }
    .letsWorkTogether .thirdLine a:first-child{
        margin-right: 10px;
    }
    .filledFromLeft span{
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;

    }
    .letsWorkTogether .secondLine button{
        height: 200px;
        width: 200px;
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0em;
        text-align: center;

    }
    .letsWorkTogether .absHolder{
        top: -100px;
    }
    .footerLinksMob{
        display: block;
    }
}
@media (max-width: 599px) {
    .letsWorkTogether{
        padding-top: 62px;
    }
    .letsWorkTogether .firstLine > img{
        display: none;
    }
    .letsWorkTogether .secondLine{
        margin-top: 133px;
    }
    .letsWorkTogether .absHolder{
        left: calc(50% - 100px);
    }
    .letsWorkTogether .thirdLine{
        flex-direction: column;
    }
    .letsWorkTogether .thirdLine a:first-child{
        margin-bottom: 10px;
    }
}