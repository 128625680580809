body .projectPage {
    background-color: #fff;
    color: #000;
}

body .projectPage a {
    color: inherit;
}

.projectPage .mainImg {
    height: 800px;
    width: 100%;
    margin-bottom: 196px;
}
.projectPage .mainScreens:nth-child(5) {
    margin-top: 194px;
}
@media (max-width: 1199px) {
    .projectPage .mainScreens:nth-child(5) {
        margin-top: 146px;
    }
}
