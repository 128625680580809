@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.mobile {
    &-menu {
        position: fixed;
        inset: 0;
        width: 100vw;
        height: 100vh;
        background-color: $blue-black;
        z-index: 100;
        transform: translateX(-100%);
        transition: transform 0.5s;

        @include sm {
            display: none;
        }

        &.show {
            transform: translateX(0);
        }

        &__header {
            display: flex;
            justify-content: flex-end;
            margin-top: 22px;
        }
    }

    &-nav {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        margin-top: 50px;

        a {
            font-size: 30px;
            line-height: 120%;
        }
    }

    &-socials {
        display: flex;
        flex-direction: column;
        row-gap: 15px;
        margin-top: 50px;

        a {
            font-size: 16px;
            line-height: 100%;
        }
    }

    &-write-us {
        display: block;
        margin-top: 50px;
        padding: 17px 30px;
        width: 100%;
        background: transparent;
        color: $white;
        font: inherit;
        font-size: 16px;
        line-height: 100%;
        text-align: center;
        border: 1px solid rgba(255, 255, 255, 0.3);
        border-radius: 30px;
    }
}
