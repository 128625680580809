@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.footer-main {
    position: relative;
    z-index: 0;

    &__inner {
        margin-top: 120px;
        padding: 50px 0 40px;

        @include sm {
            margin-top: 130px;
        }

        @include lg {
            margin-top: 100px;
        }

        @include xl {
            padding: 100px 0 115px;
        }
    }

    &__top {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        row-gap: 40px;
        font-size: 14px;
        line-height: 100%;

        @include sm {
            flex-direction: row;
        }

        @include lg {
            font-size: 16px;
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;

        a {
            display: none;

            @include sm {
                display: block;
            }
        }
    }

    &__logo {
        img {
            width: 102px;
            height: 20px;

            @include lg {
                width: 204px;
                height: 50px;
            }
        }
    }

    &__middle {
        display: flex;
        row-gap: 15px;
        column-gap: 60px;
    }

    &__nav,
    &__links {
        display: flex;
        flex-direction: column;
        row-gap: 15px;

        a {
            display: block;
            width: max-content;
        }
    }

    @include sm {
        &__links {
            display: none;
        }
    }

    &__presentation {
        display: flex;
        column-gap: 15px;

        @include only-hover {
            &:hover &-icon {
                background-color: $green;
            }
        }

        &-icon {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 50px;
            height: 50px;
            border: 1px solid rgba(255, 255, 255, 0.3);
            border-radius: 50%;
            transition: background-color 0.5s;

            img {
                width: 24px;
                height: 24px;
            }
        }

        p {
            font-size: 16px;
            line-height: 100%;

            &:last-child {
                margin-top: 10px;
                font-size: 12px;
                line-height: 120%;
                opacity: 0.6;
            }

            @include sm {
                font-size: 18px;

                &:last-child {
                    font-size: 14px;
                }
            }
        }
    }

    &__line {
        margin: 40px 0;
        width: 100%;
        height: 1px;
        background-color: rgba(255, 255, 255, 0.3);

        @include lg {
            margin: 100px 0;
        }
    }

    &__copyright {
        opacity: 0.6;
    }
}
