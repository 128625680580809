body,
html {
    margin: 0;
    font-family: "Inter", sans-serif;
    padding: 0;
}
p,
a,
h1,
h2,
h3,
h4,
h5 {
    margin: 0;
}
button {
    border: 0;
    background-color: transparent;
    cursor: pointer;
    padding: 0;
}
a {
    text-decoration: none;
    color: inherit;
    cursor: pointer;
}
.row {
    display: flex;
}
a:visited {
    color: inherit;
}
code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
.container {
    max-width: 1200px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
}

.br-1024 {
    display: none;
}
.br-900 {
    display: none;
}
.br-600 {
    display: none;
}
@media (max-width: 1199px) {
    .container {
        max-width: 900px;
    }
    .pc-br {
        display: none;
    }
    .br-1024 {
        display: block;
    }
    .br-900 {
        display: none;
    }
    .br-600 {
        display: none;
    }
}

@media (max-width: 899px) {
    .container {
        max-width: 600px;
    }
    .br-1024 {
        display: none;
    }
    .br-900 {
        display: block;
    }
    .br-600 {
        display: none;
    }
}
@media (max-width: 599px) {
    .container {
        max-width: 300px;
    }
    .br-900 {
        display: none;
    }
    .br-600 {
        display: block;
    }
}
