@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.projects {
    overflow: hidden;

    &__inner {
        padding-top: 85px;

        @include lg {
            padding-top: 150px;
        }

        @include xl {
            padding-top: 180px;
        }
    }

    &__title {
        display: flex;
        align-items: center;
        column-gap: 5px;

        span {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 40px;
            height: 40px;
            font-size: 14px;
            line-height: 120%;
            background-color: $blue-black;
            border-radius: 50%;
        }

        @include lg {
            column-gap: 15px;
        }

        @include lg {
            column-gap: 20px;

            span {
                font-size: 30px;
                width: 80px;
                height: 80px;
            }
        }
    }

    &__list {
        position: relative;
        margin-top: 20px;
        height: 325px;

        &-inner {
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            height: 100%;
            width: 570px;

            @include sm {
                width: 870px;
            }

            @include lg {
                width: 1120px;
            }

            @include xl {
                width: 1690px;
            }
        }

        @include lg {
            margin-top: 50px;
            height: 650px;
        }
    }

    &__card {
        width: 280px;
        height: 325px;
        overflow: hidden;

        &-inner {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
            padding: 15px;
            background-size: cover;
            transition: transform 0.5s;

            @include lg {
                padding: 40px;
            }

            @include only-hover {
                &:hover {
                    transform: scale(1.1);
                }
            }

            &.vitacard {
                background-image: url("./img/project-vitacard-small.png");
                background-color: #007aff;

                @include lg {
                    background-image: url("./img/project-vitacard-big.png");
                }
            }

            &.megacvet {
                background-image: url("./img/project-megacvet-small.png");
                background-color: #ff396f;

                @include lg {
                    background-image: url("./img/project-megacvet-big.png");
                }
            }

            &.gremm {
                background-image: url("./img/project-gremm-small.png");
                background-color: #cb9356;

                @include lg {
                    background-image: url("./img/project-gremm-big.png");
                }
            }

            &.vitakit {
                background-image: url("./img/project-vitakit-small.png");
                background-color: #6d76f3;

                @include lg {
                    background-image: url("./img/project-vitakit-big.png");
                }
            }

            &.all {
                background-image: url("./img/project-all-small.png");
                background-color: #191919;

                @include lg {
                    background-image: url("./img/project-all-big.png");
                }
            }
        }

        &-tag {
            padding: 5px 10px;
            font-size: 12px;
            line-height: 140%;
            background-color: rgba(255, 255, 255, 0.2);
            border-radius: 20px;

            @include lg {
                padding: 10px 20px;
                font-size: 14px;
            }
        }

        &-name {
            font-size: 18px;
            line-height: 120%;

            @include lg {
                font-size: 35px;
            }
        }

        img {
            width: 100%;
            height: 100%;
            transition: transform 0.5s;

            @include only-hover {
                &:hover {
                    transform: scale(1.1);
                }
            }
        }

        @include lg {
            width: 550px;
            height: 650px;
        }
    }
}
