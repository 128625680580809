@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.header-main {
    padding: 22px 0;

    @include sm {
        padding: 20px 0;
    }

    @include lg {
        padding: 40px 0;
    }

    @include xl {
        padding: 50px 0;
    }

    &__inner {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        img {
            width: 100px;
            height: 20px;

            @include lg {
                width: 148px;
                height: 30px;
            }
        }
    }

    &__nav {
        display: none;
        align-items: center;
        column-gap: 50px;
        font-size: 14px;
        line-height: 100%;

        @include sm {
            display: flex;
        }

        @include lg {
            font-size: 16px;
            column-gap: 100px;
        }
    }
}

.burger {
    position: relative;
    display: flex;
    outline: none;
    height: 24px;
    width: 24px;
    border: 0;
    padding: 7px 0 0;
    background: transparent;
    transition: all 250ms ease-out;
    cursor: pointer;

    @include sm {
        display: none;
    }
}

.burger:before,
.burger:after {
    content: "";
    width: 24px;
    height: 2px;
    position: absolute;
    background: $white;
    transition: all 250ms ease-out;
    will-change: transform;
}

.burger:before {
    transform: translateY(-3px);
}

.burger:after {
    transform: translateY(3px);
}

.active.burger:before {
    transform: translateY(0) rotate(45deg);
}

.active.burger:after {
    transform: translateY(0) rotate(-45deg);
}

/////

.mobileMenu .container > a {
    height: 50px;
    display: block;
}
.mobileMenu .container .filledFromLeft {
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: 1px solid #ffffff4d;
    color: white;
}
.mobileMenu .container .filledFromLeft span {
    top: calc(50% - 8px);
    left: calc(50% - 62px);
    position: absolute;
}
.mobileMenu .container .filledFromLeft .buttonBackLong {
    top: 0;
    border-radius: 30px;
    height: 50px;
}
.mobileMenu .container .filledFromLeft:hover {
    border-color: #388e3c;
}
.mobileMenu {
    transform: translateX(-100px);
    opacity: 0;
    position: fixed;
    z-index: 100;
    left: -100%;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #191919;
    transition-duration: 300ms;
    transition-property: transform, opacity;
}
.mobileMenu .container {
    flex-direction: column;
}
.mobileMenu .closeButton {
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-bottom: 49px;
}
.mobileMenu .closeButton img {
    width: 24px;
    height: 24px;
}
.mobileMenu .links {
    margin-bottom: 51px;
}
.mobileMenu .links a {
    margin-bottom: 10px;
    display: block;
}
.mobileMenu .links a:last-child {
    margin-bottom: 0;
}
.mobileMenu .links p {
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.mobileMenu .social a:first-child {
    margin-bottom: 20px;
    display: block;
}
.mobileMenu .social p {
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.mobileMenu .social {
    margin-bottom: 58px;
}
.header .mobileButtonForMenu {
    margin-top: -6px;
    display: none;
}

@media (max-width: 599px) {
    .mobileMenu.opened {
        display: block;
        transform: translateX(0px);
        opacity: 1;
        left: 0;
    }
}
