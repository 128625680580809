.block-lighter {
    background-color: $blue-black;
}

.block-title {
    font-weight: 400;
    font-size: 25px;
    line-height: 120%;
    letter-spacing: -0.02em;

    @include lg {
        font-size: 80px;
    }
}

.green-button {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: none;
    outline: none;

    span {
        position: relative;
        display: inline-block;
        padding: 18px 50px;
        font-size: 14px;
        color: $white;
        background-color: $green;
        border-radius: 30px;
        transition: 0.3s linear;
        overflow: hidden;

        @include lg {
            padding: 20px 73px;
            font-size: 18px;
        }

        &:before {
            position: absolute;
            content: "";
            top: 0;
            left: -100%;
            height: 100%;
            width: 100%;
            background-color: $green-light;
            border-radius: 30px;
            transition: 0.3s linear;
            z-index: -1;
        }
    }

    @include only-hover {
        &:hover span:before {
            left: 0;
        }
    }
}

.contact__form {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    column-gap: 15px;
    row-gap: 10px;

    @include lg {
        column-gap: 10px;
    }

    input,
    label,
    input::placeholder {
        font-weight: 400;
        font-size: 14px;
        line-height: 140%;

        @include lg {
            font-size: 18px;
        }
    }

    &-field {
        width: 100%;

        input {
            height: 50px;

            @include lg {
                height: 60px;
            }
        }
    }

    button {
        margin-top: 5px;
        flex-shrink: 0;

        @include lg {
            margin-top: 0;
        }
    }

    &-desc {
        margin-top: 15px;
        font-size: 12px;
        line-height: 140%;
        color: rgba(255, 255, 255, 0.6);

        @include lg {
            margin-top: 20px;
            font-size: 14px;
            max-width: 334px;
        }
    }
}
