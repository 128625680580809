.projectBanner{
    position: relative;
    width: 100%;
    height: 100vh;
    background-color: #EAEDF0;
    overflow: hidden;
    min-height: 800px;
}
.projectBanner .container{
    position: relative;
    display: flex;
    justify-content: space-between;
}
.projectBanner .servicesHolder{
    margin-top: 539px;
    opacity: 0;
}
.projectBanner .animatedCircleButton{
    opacity: 0;
}
.projectBanner .projectBannerImg{
    opacity: 0;
}
.projectBanner .servicesHolder label{
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase;
    color:#00000080;
    margin-bottom: 21px;
    display: block;
}
.projectBanner .roundButton{
    width: 220px;
    height: 220px
}
.projectBanner .servicesHolder p{
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    width: 250px;
}
.projectBanner .runningLine{
    position: absolute;
    top: 230px;
    z-index: 2;
    opacity: 0;
    white-space: nowrap;
    overflow: hidden;
    pointer-events: none;
    margin: 0 auto;
    width: calc(100% + 20px);

}
.projectBanner .runningLine h1{
    display: inline-block;
    font-size: 150px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0;
    text-align: left;
    width: max-content;
    animation: changePosInitProject 200s linear infinite;
    padding-left: 100%;
    animation-delay: -100s;

}
.projectBanner .runningLine.shadow h1{
    animation-delay: -200s;
}
.projectBannerImg{
    width: 420px;
    height: 570px;position: absolute;
    top: 130px;
    left: calc(50% - 210px);

}

.projectBanner .runningLine__block {
    font-size: 150px;
    font-weight: 400;
    line-height: 180px;
    letter-spacing: 0;
}

@keyframes changePosInitProject {
    0% {
        transform: translate(0, 0);
    }
    100% {
        transform: translate(-100%, 0);
    }
}

.projectBanner .animatedCircleButton{
    position: relative;
    overflow: hidden;
    background-color: black;
    width: 220px;
    height: 220px;
    border-radius: 50%;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 460px;
    margin-right: 69px;
}
.projectBanner .animatedCircleButton p{
    z-index: 10;
    transform: translate3d(0, 0,0) !important;
    width: 140px;
}
.projectBanner .buttonBack{
    width: 150%;
    height: 200%;
    background-color: #388E3C;
    border-radius: 50%;
    top: -50%;
    left: -25%;
    transform: translate3d(0,-76%,0);
    position: absolute;
    transition-duration: 300ms;
    transition: background-color ease-in-out .25s;

}
.projectBanner .animatedCircleButton:hover .buttonBack{
    top: 0;

}

@media (max-width: 1199px) {
    .projectBanner{
        min-height: 650px;
    }
    .projectBanner .runningLine{
        top: 120px;
    }
    .projectBanner .projectBannerImg{
        width: 320px;
        height: 450px;
        top: 110px;
        left: calc(50% - 160px)
    }
    .projectBanner .servicesHolder{
        margin-top: 422px;
    }
    .projectBanner .animatedCircleButton{
        margin-right: 0;
        margin-top: 340px;
    }

}
@media (max-width: 899px) {
    .projectBanner .runningLine{
        top: 90px;
    }
    .projectBanner .runningLine h1{
        font-size: 70px;
        font-weight: 400;
        line-height: 84px;
        letter-spacing: 0;
        text-align: left;

    }
    .projectBanner .runningLine__block {
        font-size: 70px;
        line-height: 84px;
    }
    .projectBanner .projectBannerImg{
        width: 250px;
        height: 320px;
        left: calc(50% - 125px);
        top: 80px
    }
    .projectBanner .animatedCircleButton{
        z-index: 3;
        margin-top: 200px;
        width: 200px;
        height: 200px;
        margin-right: 40px;
    }
    .projectBanner .servicesHolder{
        margin-top: 279px;
    }
    .projectBanner .servicesHolder label{
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0;
        text-align: left;
        margin-bottom: 7px;
    }
    .projectBanner .servicesHolder p{
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0;
        text-align: left;

        width: 150px;
    }
    .projectBanner .animatedCircleButton p{
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        letter-spacing: 0;
        text-align: center;

    }
}

@media (max-width: 599px) {
    .projectBanner .servicesHolder{
        margin-top: 557px;
    }
    .projectBanner .animatedCircleButton{
        margin-left: -100px;
        margin-top: 348px;
        min-width: 200px;
        margin-right: 50px;
    }
    .projectBanner .projectBannerImg{
        width: 270px;
        height: 368px;
        left: calc(50% - 135px)
    }
    .projectBanner .runningLine{
        top: 200px
    }
}
@media (min-width: 1200px) and (min-height: 1000px){
    .projectBanner{
        display: flex;
        align-items: center;
    }
    .projectBanner .runningLine{
        top: calc(50% - 140px)
    }
    .projectBanner .projectBannerImg{
        top: calc(50% - 230px)
    }
}
@media (max-width: 1199px) and (min-height: 700px){
    .projectBanner{
        display: flex;
        align-items: center;
    }
    .projectBanner .runningLine{
        top: calc(50% - 140px)
    }
    .projectBanner .projectBannerImg{
        top: calc(50% - 170px)
    }
}
@media (max-width: 899px) and (min-height: 700px){
    .projectBanner{
        display: flex;
        align-items: center;
    }
    .projectBanner .runningLine{
        top: calc(50% - 100px)
    }
    .projectBanner .projectBannerImg{
        top: calc(50% - 120px)
    }
}

@media (max-width: 599px) and (min-height: 700px){
    .projectBanner{
        display: flex;
        align-items: center;
    }
    .projectBanner .runningLine{
        top: calc(50% - 80px)
    }
    .projectBanner .projectBannerImg{
        top: calc(50% - 210px)
    }
}