@import "vars";
@import "mixins";

// animated-input

.animated-input input {
    width: 100%;
    height: 60px;
    border: 0;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    background-color: transparent;
    color: white;
    outline: none !important;
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    cursor: pointer;
    transition-duration: 300ms;
}
.animated-input {
    position: relative;
    cursor: pointer;
}
.animated-input label {
    position: absolute;
    top: 16px;
    left: 0;
    color: rgba(255, 255, 255, 0.5);
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0;
    text-align: left;
    transition-duration: 300ms;
    cursor: pointer;
}
.animated-input:hover label {
    color: white;
}
.animated-input:hover input {
    border-color: rgba(255, 255, 255, 0.4);
}
.animated-input input:focus ~ label,
.animated-input input:not(:placeholder-shown) ~ label {
    color: rgba(255, 255, 255, 0.5);
    top: 1px;
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0;
    text-align: left;
}
.animated-input input:focus,
.animated-input input:not(:placeholder-shown) {
    border-color: rgba(255, 255, 255, 0.4);
}

// animated-link

.animated-link {
    & .line {
        width: 0;
        height: 1px;
        margin-top: 4px;
        background-color: $white;
        transition: width 0.3s;
    }

    @include only-hover {
        &:hover .line {
            width: 100%;
        }
    }
}

// animation-magnetic

.animation-magnetic {
    transition-duration: 500ms;
}

//.toFadeUp {
//    opacity: 0;
//    transform: translateY(50px);
//    transition-duration: 700ms;
//}
//
//.toFadeDown {
//    opacity: 0;
//    transform: translateY(-50px);
//    transition-duration: 700ms;
//}
//
//.fadeUp-active {
//    opacity: 1 !important;
//    transform: scale(1) translateY(0px) !important;
//}
