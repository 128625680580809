@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.apps-img {
    display: flex;
    justify-content: center;
    width: 100%;
    height: 320px;
    background-color: $blue-black;
    overflow: hidden;

    @include lg {
        height: 650px;
    }

    img {
        height: 414px;
        object-fit: contain;

        @include lg {
            height: 900px;
        }
    }
}
