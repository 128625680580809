.projectAbout {
    padding-top: 182px;
    padding-bottom: 91px;
}
.projectAbout h2 {
    font-size: 80px;
    font-weight: 400;
    line-height: 96px;
    letter-spacing: 0em;
    text-align: left;
}
.projectAbout > .container > .row {
    justify-content: space-between;
    margin-top: 77px;
}
.projectAbout .textProjectAbout {
    width: 650px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.projectAbout .textProjectAbout .year {
    margin-top: 68px;
}
.projectAbout .textProjectAbout label,
.projectAbout .solvedProblems label {
    font-size: 14px;
    font-weight: 400;
    line-height: 17px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000080;
    text-transform: uppercase;
    display: block;
}
.projectAbout .textProjectAbout label {
    margin-bottom: 18px;
}
.projectAbout .solvedProblems label {
    margin-bottom: 27px;
}
.projectAbout .textProjectAbout p {
    font-size: 25px;
    font-weight: 400;
    line-height: 35px;
    letter-spacing: 0em;
    text-align: left;
}
.projectAbout .textProjectAbout span {
    font-size: 40px;
    font-weight: 400;
    line-height: 48px;
    letter-spacing: 0em;
    text-align: left;
}
.projectAbout .solvedProblems {
    width: 389px;
}
.projectAbout .solvedProblems .inner > .row {
    border-bottom: 1px solid rgba(0, 0, 0, 0.2);
    justify-content: space-between;
    padding-top: 20px;
    padding-bottom: 20px;
}
.projectAbout .solvedProblems .inner > .row:first-child {
    border-top: 1px solid rgba(0, 0, 0, 0.2);
}
.projectAbout .solvedProblems .inner .row span {
    font-size: 12px;
    font-weight: 400;
    line-height: 12px;
    letter-spacing: 0em;
    text-align: left;
    color: #00000080;
}
.projectAbout .yearMob {
    display: none;
}
.projectAbout .solvedProblems .inner .row p {
    font-size: 18px;
    font-weight: 400;
    line-height: 25px;
    letter-spacing: 0em;
    text-align: left;
    width: 360px;
}

/* contact-us */
.projectAbout .contact-us {
    margin-top: 20px;
    padding: 90px 30px 174px 58px;
    background-color: #eaedf0;
}
.projectAbout .contact-us__title {
    color: #1e1e1e;
    font-size: 64px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
.projectAbout .contact-us__subtitle {
    margin-top: 6px;
    padding-right: 360px;
    color: rgba(30, 30, 30, 0.87);
    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 120%;
}
.projectAbout .contact-us .secondLine {
    height: 1px;
    width: 100%;
    background-color: #388e3c;
    margin-top: 60px;
    position: relative;
}

.projectAbout .contact-us .absHolder {
    position: absolute;
    top: -109px;
    right: 63px;
}
.projectAbout .contact-us .secondLine button {
    width: 202px;
    height: 202px;
    border-radius: 50%;
    background-color: #388e3c;
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
    font-size: 18px;
    font-weight: 400;
    line-height: 18px;
    letter-spacing: 0em;
    text-align: center;
    transition-duration: 300ms;
}

@media (max-width: 899px) {
    .projectAbout .contact-us {
        padding: 40px 30px 124px 38px;
    }
    .projectAbout .contact-us__title {
        font-size: 24px;
    }
    .projectAbout .contact-us__subtitle {
        margin-top: 12px;
        padding-right: 300px;
        font-size: 20px;
    }
}

@media (max-width: 599px) {
    .projectAbout .contact-us {
        padding: 30px 20px 124px;
    }
    .projectAbout .contact-us__subtitle {
        padding-right: 0;
    }
    .projectAbout .contact-us .secondLine {
        margin-top: 140px;
    }
    .projectAbout .contact-us .absHolder {
        right: 28px;
    }
}

.projectAbout .contact-us .secondLine.vitacarta,
.projectAbout .contact-us .secondLine.vitacarta button {
    background-color: #007aff;
}

.projectAbout .contact-us .secondLine.megacvet,
.projectAbout .contact-us .secondLine.megacvet button {
    background-color: #ff396f;
}

.projectAbout .contact-us .secondLine.gremm,
.projectAbout .contact-us .secondLine.gremm button {
    background-color: #cb9356;
}

.projectAbout .contact-us .secondLine.vitakit,
.projectAbout .contact-us .secondLine.vitakit button {
    background-color: #6976f3;
}

@media (max-width: 1199px) {
    .projectAbout {
        padding-top: 133px;
    }
    .projectAbout > .container > .row {
        margin-top: 33px;
    }
    .projectAbout .textProjectAbout label {
        margin-bottom: 15px;
    }
    .projectAbout .textProjectAbout .year {
        margin-top: 48px;
    }
    .projectAbout .textProjectAbout {
        width: 450px;
    }
    .projectAbout .solvedProblems label {
        margin-bottom: 20px;
    }
    .projectAbout {
        padding-bottom: 71px;
    }
    .parallaxBlock {
        height: 600px;
    }
}
@media (max-width: 899px) {
    .parallaxBlock {
        height: 350px;
    }
    .parallaxBlock img {
        height: 450px;
    }
    .projectAbout {
        padding-top: 93px;
        padding-bottom: 50px;
    }
    .projectAbout h2 {
        font-size: 30px;
        font-weight: 400;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
    }
    .projectPage .mainScreens:nth-child(5) {
        margin-top: 93px;
    }
    .projectAbout > .container > .row {
        margin-top: 42px;
    }
    .projectAbout .textProjectAbout {
        width: 270px;
    }
    .projectAbout .solvedProblems {
        width: 280px;
    }
    .projectAbout .textProjectAbout label {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 13px;
    }
    .projectAbout .textProjectAbout p {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }
    .projectAbout .solvedProblems label {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 22px;
    }
    .projectAbout .solvedProblems .inner > .row {
        padding-top: 15px;
        padding-bottom: 15px;
    }
    .projectAbout .solvedProblems .inner .row p {
        font-size: 16px;
        font-weight: 400;
        line-height: 22px;
        letter-spacing: 0em;
        text-align: left;
        width: 251px;
    }
    .projectAbout .textProjectAbout label {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
    }
    .projectAbout .textProjectAbout span {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }
}
@media (max-width: 599px) {
    .parallaxBlock {
        height: 320px;
    }
    .parallaxBlock img {
        height: 420px;
    }
    .projectAbout {
        padding-top: 73px;
        padding-bottom: 55px;
    }
    .projectAbout > .container > .row {
        margin-top: 31px;
        flex-direction: column;
    }
    .projectAbout .textProjectAbout label {
        margin-bottom: 13px;
    }

    .projectAbout .textProjectAbout .year {
        display: none;
    }
    .projectAbout .solvedProblems {
        margin-top: 43px;
    }
    .projectAbout .year {
        display: flex;
        flex-direction: column;
        margin-top: 48px;
    }
    .projectAbout .year label {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
        margin-bottom: 8px;
        text-transform: uppercase;
        color: #00000080;
    }
    .projectAbout .year span {
        font-size: 20px;
        font-weight: 400;
        line-height: 24px;
        letter-spacing: 0em;
        text-align: left;
    }
    .projectPage .mainScreens:nth-child(5) {
        margin-top: 78px;
    }
}
.parallaxBlock {
    height: 800px;
    overflow: hidden;
}
.parallaxBlock img {
    width: 100%;
    object-fit: cover;
}

@media (max-width: 1199px) {
    .parallaxBlock {
        height: 600px;
    }
}

@media (max-width: 899px) {
    .parallaxBlock {
        height: 350px;
    }
}

@media (max-width: 599px) {
    .parallaxBlock {
        height: 320px;
    }
}
