@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.steps {
    &__inner {
        padding: 80px 0 40px;

        @include lg {
            padding: 150px 0 100px;
        }

        @include xl {
            padding: 180px 0 100px;
        }
    }

    &__list {
        margin-top: 20px;

        @include lg {
            margin-top: 40px;
        }
    }

    &__item {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        padding: 15px 0;
        border-top: 1px solid rgba(255, 255, 255, 0.2);

        &:last-child {
            border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        }

        @include sm {
            flex-direction: row;
        }

        @include lg {
            padding: 20px 0;
        }

        &-title {
            font-weight: 400;
            font-size: 18px;
            line-height: 120%;
            letter-spacing: -0.01em;
            width: 300px;

            @include lg {
                font-size: 30px;
                width: 450px;
                padding-right: 50px;
            }

            @include xl {
                width: 600px;
            }
        }

        &-content {
            flex: 1 1 auto;
            display: flex;
            flex-direction: column;
            row-gap: 10px;

            @include lg {
                flex-direction: row;
            }
        }

        &-desc,
        &-time {
            font-size: 14px;
            line-height: 120%;
            letter-spacing: -0.01em;

            @include lg {
                font-size: 18px;
            }
        }

        &-desc {
            width: 300px;

            @include lg {
                padding-right: 50px;
                width: 350px;
            }

            @include xl {
                width: 400px;
            }
        }

        &-time {
            flex: 1 1 auto;

            @include lg {
                text-align: right;
            }
        }
    }
}
