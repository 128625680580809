.filledFromLeft{
    position: relative;
    overflow: hidden;
}
.buttonBackLong{
    width: 100%;
    height: 60px;
    background-color: #388E3C;
    border-radius: 30px;
    left: 0;
    transform: translate3d(100%,0,0);
    position: absolute;
    transition-duration: 300ms;
    transition: background-color ease-in-out .25s;
}
.filledFromLeft span{
    z-index: 10;
}

