@mixin only-hover {
    @media (hover: hover) {
        @content;
    }
}

@mixin sm {
    @media (min-width: 640px) {
        @content;
    }
}

@mixin lg {
    @media (min-width: 992px) {
        @content;
    }
}

@mixin xl {
    @media (min-width: 1240px) {
        @content;
    }
}
