@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.problems {
    &__inner {
        padding: 80px 0 30px;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        row-gap: 15px;

        @include sm {
            padding: 80px 0 40px;
            flex-direction: row;
        }

        @include lg {
            padding: 150px 0 60px;
        }

        @include xl {
            padding: 180px 0 60px;
        }
    }

    &__handshake {
        display: flex;
        column-gap: 10px;

        img {
            width: 40px;
            height: 40px;
        }

        p {
            max-width: 114px;
            font-size: 14px;
            line-height: 120%;
        }

        @include lg {
            column-gap: 20px;

            img {
                width: 60px;
                height: 60px;
            }

            p {
                font-size: 20px;
                max-width: 168px;
            }
        }
    }

    h3 {
        max-width: 300px;
        font-size: 20px;
        font-weight: 400;
        line-height: 120%;
        letter-spacing: -0.01em;

        @include lg {
            font-size: 40px;
            max-width: 450px;
        }

        @include xl {
            max-width: 600px;
        }
    }

    &__marquee {
        position: relative;
        display: flex;
        overflow: hidden;

        &-content {
            flex-shrink: 0;
            display: flex;
            min-width: 100%;
            animation: marquee 18s linear infinite;
        }

        &--right &-content {
            animation-direction: reverse;
        }

        &-item {
            padding: 13px 50px;
            flex-shrink: 0;
            font-size: 20px;
            line-height: 120%;
            background-color: $blue-black;
            border-radius: 80px;
            white-space: nowrap;

            @include lg {
                padding: 50px;
                font-size: 40px;
            }
        }
    }
}
