.bottomLine{
    width: 0;
    background-color: black;
    height: 1px;
    margin-top: 4px;
    transition-duration: 300ms;
}
.bottomLine.white{
    background-color: white;
}
.bottomLine.green{
    background-color: #388E3C;
}
.animatedLink:hover .bottomLine{
    width: 100%;
}
