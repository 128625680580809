@import "../../../../styles/vars";
@import "../../../../styles/mixins";

.about {
    margin-top: 80px;

    @include lg {
        margin-top: 125px;
    }

    &__background {
        background-color: #191919;
        background-image: url(./img/about-bg.jpg);
        background-position: 50%;
        background-repeat: no-repeat;
        background-size: cover;
    }

    &__inner {
        padding: 40px 0 26px;

        @include sm {
            padding: 40px 0 50px;
        }

        @include xl {
            padding: 40px 0 70px;
        }
    }

    &__title {
        position: relative;
        display: inline-block;
        color: #fff;
        font-size: 22px;
        font-style: normal;
        font-weight: 400;
        line-height: 120%;

        @include lg {
            font-size: 50px;
        }

        img {
            position: absolute;

            top: -7px;
            right: -50px;
            width: 36px;
            height: 36px;

            @include lg {
                top: -10px;
                right: -90px;
                width: 66px;
                height: 66px;
            }
        }
    }

    &__blocks {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        row-gap: 20px;
        margin-top: 40px;

        @include sm {
            column-gap: 30px;
            flex-direction: row;
        }

        @include lg {
            column-gap: 50px;
            margin-top: 60px;
        }

        &-item {
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 40px 20px;
            border-radius: 36px;
            background: rgba(146, 146, 146, 0.16);
            box-shadow: -34px 9px 78px 0px rgba(0, 0, 0, 0.28), 1px -1px 0px 0px rgba(255, 255, 255, 0.17);
            text-align: center;
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 120%;

            @include lg {
                padding: 50px 25px;
                font-size: 20px;
            }
        }
    }
}
