@import "animations";
@import "locomotive-scroll";

html {
    scroll-behavior: smooth;
}

body {
    font-family: "Inter", sans-serif;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    background-color: $black;
    color: $white;
    max-height: 100vh;
    overflow: hidden;
}

.container {
    margin: 0 auto;
    padding: 0 10px;
    max-width: 320px;

    @include sm {
        padding: 0 20px;
        max-width: 640px;
    }

    @include lg {
        max-width: 940px;
    }

    @include xl {
        max-width: 1240px;
    }
}

ul {
    padding: 0;
}

.no-scroll {
    overflow: hidden;
}

a {
    color: $white;
}

*,
*:before,
*:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

@keyframes marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(-100%);
    }
}

@keyframes right-marquee {
    from {
        transform: translateX(0);
    }
    to {
        transform: translateX(100%);
    }
}

.modal {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    inset: 0;
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    opacity: 0;
    pointer-events: none;
    transition: all 0.5s;
    z-index: 100;

    &__content {
        padding: 30px 50px;
        flex-shrink: 0;
        font-size: 20px;
        line-height: 120%;
        background-color: $blue-black;
        border: 2px solid $black;
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.5);
        border-radius: 80px;
        text-align: center;

        @include lg {
            font-size: 24px;
        }
    }

    &.show {
        visibility: visible;
        opacity: 1;
    }
}
