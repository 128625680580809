.fade-enter {
    opacity: 0;
    z-index: 1;
}
.fade-enter.fade-enter-active {
    opacity: 1;

    transition: opacity 300ms linear;
}
.fade-exit {
    opacity: 1;
}
.fade-exit.fade-exit-active {
    opacity: 0;

    transition: opacity 300ms linear;
}

[data-aos="card-animation"] {
    opacity: 0;
    transform: scale(0.9) translateY(50px);
    transition-property: transform, opacity;
}

[data-aos="card-animation"].aos-animate {
    opacity: 1;
    transform: scale(1) translateY(0px);
}
[data-aos="client-animation"] {
    opacity: 0;
    transform: scale(0.9) translateY(50px);
    transition-property: transform, opacity;
    transition-duration: 1500ms;
}

[data-aos="client-animation"].aos-animate {
    opacity: 1;
    transform: scale(1) translateY(0px);
}