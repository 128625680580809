.header{
    position: fixed;
    width: 100%;
    z-index: 11;
    transition-duration: 300ms;
    top: 0;
}
.header .container{
    display: flex;
    justify-content: space-between;
    padding-top: 50px;
    padding-bottom: 50px;
}
.header .headerButtons{
    display: flex;
    width: 389px;
    justify-content: space-between;
    height: 21px;
    margin-top: 7px;
}
.header .headerButtons a{
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    cursor: pointer;
    transition-duration: 300ms;
}
.header .headerButtons a:hover{
    border-color: black;

}
.header .mobileMenu .container > a{
    height: 50px;
    display: block;

}
.header .mobileMenu .container .filledFromLeft{
    width: 100%;
    height: 50px;
    border-radius: 30px;
    border: 1px solid #FFFFFF4D;
    color: white;
}
.header .mobileMenu .container .filledFromLeft span{
    top: calc(50% - 8px);
    left: calc(50% - 62px);
    position: absolute;
}
.header .mobileMenu .container .filledFromLeft .buttonBackLong{
    top: 0;
    border-radius: 30px;
    height: 50px;
}
.header .mobileMenu .container .filledFromLeft:hover{
    border-color: #388E3C;
}
.header.white img{
    filter: invert(1);
}
.header.filled{
    background-color: #FFFFFF;
}
.header.filled.white{
    background-color: #000000;
}
.header.white .headerButtons a{
    color: white;
}
.header.white .headerButtons .animatedLink .bottomLine{
    background-color: white;
}
.header.hidden{
    top: -134px;
}
.header .mobileMenu{
    transform: translateX(-100px);
    opacity: 0;
    position: fixed;
    z-index: 100;
    left: -100%;
    top: 0;
    width: 100vw;
    height: 100vh;
    background: #191919;
    transition-duration: 300ms;
    transition-property: transform, opacity;
}
.header .mobileMenu .container{
    flex-direction: column;
}
.header .mobileMenu .closeButton{
    width: 24px;
    height: 24px;
    margin-left: auto;
    margin-bottom: 49px;
}
.header .mobileMenu .closeButton img{
    width: 24px;
    height: 24px;
}
.header .mobileMenu .links{
    margin-bottom: 51px;
}
.header .mobileMenu .links a{
    margin-bottom: 10px;
    display: block;
}
.header .mobileMenu .links a:last-child{
    margin-bottom: 0;
}
.header .mobileMenu .links p{
    font-size: 30px;
    font-weight: 400;
    line-height: 36px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.header .mobileMenu .social a:first-child{
    margin-bottom: 20px;
    display: block;
}
.header .mobileMenu .social p{
    font-size: 16px;
    font-weight: 400;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: white;
}
.header .mobileMenu .social{
    margin-bottom: 58px;
}
.header .mobileButtonForMenu{
    margin-top: -6px;
    display: none;
}

@media (max-width: 899px) {
    .header .container{
        padding-top: 23px;
        padding-bottom: 16px;
    }
    .header .container a img{
        width: 100px;
        height: 20px;
    }
    .header .headerButtons{
        width: 265px;
    }
    .header .headerButtons a{
        font-size: 14px;
        font-weight: 400;
        line-height: 14px;
        letter-spacing: 0em;
        text-align: left;
    }
}
@media (max-width: 599px) {
    .header .headerButtons{
        display: none;
    }
    .header .container a img{
        width: 98px;
    }
    .header .mobileMenu.opened{
        display: block;
        transform: translateX(0px);
        opacity: 1;
        left: 0;
    }
    .header > .container{
        align-items: center;
    }
    .header .mobileButtonForMenu{
        display: block;
    }
}